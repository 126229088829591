import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from 'react-grid-system';
import { HeroPatients } from 'components/hero';

// import page style
import './site-map.scss';

const SiteMapPage = () => {
	return (
		<Layout indication='patient' className='patient-site-map'>
			<Seo pageTitle='Site Map' />

			<div className='body-content-container'>
				<div className='body-content'>
					<HeroPatients heroImgAlt='page hero' className='sub-page-hero'>
						<h1>Site Map</h1>
					</HeroPatients>
					<section>
						<Row>
							<Col xs={12}>
								<div className='content'>
									<ul className='no-bullets'>
										<li>
											<a href='/'>OxyContin Home Page</a>
										</li>
										<li>
											<a href='/savings-program/'>Savings Program</a>
										</li>
										<li>
											<a href='/support-materials/'>Support Materials</a>
										</li>
										<li className='indent'>
											<a href='/support-materials/'>
												Helpful Information
											</a>
										</li>
										<li>
											<a href='/important-safety-information/'>
												Indication/Important Safety Information
											</a>
										</li>
										<li>
                                        <a  href="https://www.purduepharma.com/healthcare-professionals/ask-purdue-medical/#report-adverse-event" target="_blank" rel="noopener noreferrer">
                                    Report an Adverse Event
                                    </a>
							</li>
                            <li>
							    <a  href="https://www.purduepharma.com/healthcare-professionals/ask-purdue-medical/#report-adverse-event" target="_blank" rel="noopener noreferrer">
                                Event Product Quality Complaint
                                    </a>
							</li>
                           
										<li>
											<a href='/contact-us/'>Contact Us</a>
										</li>
										<li>
											<a href='/terms-of-use/'>Terms of Use</a>
										</li>
										<li>
                                        <a  href="https://www.purduepharma.com/about/ethics-and-compliance/ccpa/" target="_blank" rel="noopener noreferrer">
                                Privacy Policy
                                </a>
										</li>
									</ul>
								</div>
							</Col>
						</Row>
					</section>
				</div>
			</div>
		</Layout>
	);
};


export default SiteMapPage;
